// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.


// Single container class with breakpoint max-widths
.container,
  // 100% wide container at all breakpoints
.container-fluid {
  @include make-container();
  @include make-container-max-widths();
}

// Row
//
// Rows contain your columns.

.row {
  @include make-row();
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns();

#migrate_target {
  position: relative;
  z-index:1;
  .scrollme {
    @include media-breakpoint-up(lg) { 
      position:fixed;

      width:340px;
    }
}
}
.o-form {

  &__loading {
    z-index: 10;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background: white;
    transition: opacity $transition-base;

    .a-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
  }
  span#more {
    height:0; 
    overflow:hidden;
    display:block;
    transition: height 0.3s linear;
  }
  span#more.renderShow {
    height:159px; 
    opacity:1;
    
  }
  .form__content-annotation {
    .details__trigger {
      font-size:14px;
      line-height:18px;
      color: #1C6BBA;
      cursor: pointer;
      white-space: nowrap;
      -webkit-transition: color 0.2s ease-in-out;
      transition: color 0.2s ease-in-out;
    }
    .details__trigger-shrink {
      display:none;
    }
  }
  .desktop_line {
    @include media-breakpoint-up(lg) { 
      background:#D5D5D5;
      height:1px;
      position:absolute;
      width:100%;
      left:0;
      top:0;

    }
    &:after {
      content:' ';
      height:1px;
      background: #d5d5d5;
      width:1px;
      top:0;
      right:-1px;
      position:absolute;

    }

  }
  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .form {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 0 0 auto;
      padding: 2rem 3rem;
      @media screen and (max-width:991px) { 
      border-bottom: solid 1px $grey-300;
      }
      &-text {
        font-size: 16px;
        line-height: 1.5;
        margin: 0;
      }

      &-close {
        padding: 2rem;
        margin: -2rem;
      }
    }

    &__content {
      overflow-x: hidden;
      overflow-y: auto;
      flex: 10 1 auto;
      padding: 1rem 2rem;
      &.showscroller {
        overflow-y:visible;
       max-height:830px;
       padding:1rem 2rem 2.5rem 2rem;
      }
      @include media-breakpoint-up(xs) {
        padding: 1rem 3rem;
      }

      &-section {
        margin-top: 1.5rem;
      }

      &-subtitle {
        display: block;
        margin-bottom: .5rem;
        font-size: 14px;
        font-weight: $font-weight-medium;
        line-height: 24px;
      }

      &-field {
        margin-top: 1rem;

        &:first-child {
          margin-top: 0;
        }
      }

      &-consents {
        margin-top: 2rem;
      }

      &-annotation {
        padding-top: 2rem;
        border-top: solid 1px $grey-300;
        margin-top: 2rem;
        font-size: 12px;
        line-height: 1.5;
        color: $grey-400;

        p:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__datetime {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;
      line-height: 24px;

      @include media-breakpoint-up(sm) {
        justify-content: space-between;
      }
    }

    .datetime {
      &__date {
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 1.25rem;

        @include media-breakpoint-up(sm) {
          flex: 1 1 auto;
          width: auto;
          margin: 0;
        }
      }

      &__time {
        flex: 1 1 auto;
        width: 50%;

        @include media-breakpoint-up(sm) {
          flex: 0 0 auto;
          width: auto;
          margin-left: 1rem;
        }
      }

      &__text {
        flex: 0 0 auto;
        width: 100%;
        margin-top: 1rem;
        font-size: 12px;
        line-height: 18px;
        color: $grey-400;

        @include media-breakpoint-up(sm) {
          margin-top: 0;
        }
      }
    }

    &__footer {
      flex: 0 0 auto;
      padding: 3rem;
      &.shadow {
      box-shadow: 0px -4px 4px rgba(213, 213, 213, 0.5);
      
      }

      &-button {
        max-width: unset;
        width: 100%;
      }
    }
  }

  &__thanks {
    position: relative;
    display: none;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 3rem;
  }

  .thanks {
    &__close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 2rem;
    }

    &__inner {

    }

    &__image {
      width: 100%;
    }

    &__title {
      margin-bottom: 2rem;
      text-align: center;
      color: $grey-400;
    }
  }

  &.is-loading {
    .o-form {
      &__loading {
        pointer-events: all;
        opacity: 1;
      }
    }
    .a-select {
      display:none;
    }
  }

  &.is-completed {

    .o-form {
      &__form {
        display: none;
      }

      &__thanks {
        display: flex;
        .thanks__title {
          padding-top:1rem;
        }
      }
    }
    .a-select {
      display:none;
    }
  }
}